import React from "react";

import SlackUI from "../components/slack-ui";

const Slack = () => {
  return (
    <div className="p-20">
      <SlackUI />
    </div>
  );
};

export default Slack;
